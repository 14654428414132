<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <SadTear>
        <h1>ページが見つかりませんでした。</h1>
        <p>お探しのページは、掲載が終了したか現在は存在しない可能性があります。トップページからお探しください。</p>
      </SadTear>
    </section>
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";
import { mapGetters } from 'vuex';
import SadTear from "@/components/atom/sad-tear.vue";

export default {
  data() {
    return {
      HeadSetting: {
        Left: 'backBtn',
        Right: 'mypage',
        Center: 'search'
      },
      title: 'ページが見つかりませんでした。',
      description: 'お探しのページが見つかりませんでした。'      
    };
  },
  components: {
    Head,
    SadTear
  },
  computed: {
    ...mapGetters([
      'searchWord'
    ]),
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },

  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1ab3ff;
}

.onePage{
  padding: 0 16px;
}

.onePage h1{
  text-align: center;
}

.onePageIcon{
  width: 100%;
  font-size: 6rem;
  text-align: center;
  line-height: 1rem;
  margin: 0;
}
</style>
